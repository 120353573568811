import React, { useContext } from 'react';
import { AnalyticsContext } from 'shared/contexts/analyticsContext';
import { debounce } from 'lodash';
import env from '../../../config/env';

export const ResourceAbout = () => {
    const { analytics } = useContext(AnalyticsContext);

    const onPlay = () => {
        analytics.event({
            category: 'User',
            action: 'TRAINING_VIDEO_PLAY',
        });
    };

    return (
        <>
            <br />
            <br />
            <h3>About My Ads</h3>
            <br />
            <p>
                My Ads by Best Buy Ads self-service platform enables access to near real-time campaign performance
                reporting for Onsite Display and Sponsored Products campaigns. My Ads provides transparency and 24/7
                &quot;always-on&quot; direct access to campaign performance reporting for our advertising partners. Our
                robust, comprehensive reporting includes high-level summary metrics, daily trends and visuals, and
                different &quot;slices&quot; of data directly available, right at your fingertips. 
            </p>
            <br />
            <video controls poster="/resources/MyAdsThumb1.jpg" onPlay={debounce(onPlay, 5000)}>
                <source src="/resources/MyAdsIntro.mp4" type="video/mp4" />
                <track kind="captions" srcLang="en" src="/resources/MyAdsIntroCC.vtt" />
                Your browser does not support HTML video.
            </video>
            <br />
            <p>
                Click{' '}
                <a
                    href={`${env.ASSETS_BASE_URL}/BBY_Ads_Final_${env.ASSETS_VERSION_NUMBER}.pdf`}
                    download="MyAdsJobAID"
                    target="_blank"
                    rel="noopener"
                >
                    here
                </a>{' '}
                to download the Intro to My Ads job aid.
            </p>
            <br />
            <h3>FAQs</h3>
            <br />
            <p>
                <b>What products can I report on from the My Ads webapp? </b>
                <br />
                Onsite Display, Onsite Video, Offsite Display, Offsite Video, Sponsored Products, and Paid Search.
            </p>
            <br />
            <p>
                <b>Where can I get reporting for other products? </b>
                <br />
                From your Best Buy Ads contact.
            </p>
            <br />
            <p>
                <b>Can I see other metrics not listed in reporting? </b>
                <br />
                Many additional metrics can be calculated manually by using the metrics available in the existing
                reports. If you wish to see additional metrics, you can submit feedback to your Best Buy Ads contact so
                we can add these as future reporting enhancements when possible.
            </p>
            <br />
            <p>
                <b>Is historical data available? </b>
                <br />
                <ul>
                    <li>Onsite Display data is available from February 1, 2021 and onward</li>
                    <li>Sponsored Products data is available from August 1, 2021 and onward</li>
                </ul>
            </p>
            <br />
            <p>
                <b>Can I sum transactional data across different campaign types? </b>
                <br />
                No, the same transactional data may be accounted for across multiple campaign types. Summing this data
                may result in inaccurate representation of campaign performance.
            </p>
            <br />
            <p>
                <b>As an admin, what responsibilities do I have and how do I manage users? </b>
                <br />
                As an admin, you will manage My Ads user access for others within the organization(s) you represent and
                be accountable for their actions. Click{' '}
                <a href="/terms" target="_blank" rel="noopener">
                    here
                </a>{' '}
                to see a full list of terms and responsibilities.
            </p>
            <br />
            <video controls poster="/resources/MyAdsThumb1.jpg" onPlay={debounce(onPlay, 5000)}>
                <source src="/resources/MyAdsAdmin.mp4" type="video/mp4" />
                <track kind="captions" srcLang="en" src="/resources/MyAdsAdmin.vtt" />
                Your browser does not support HTML video.
            </video>
            <br />
            <p>
                Click{' '}
                <a
                    href={`${env.ASSETS_BASE_URL}/BBY_Ads_Admin_Version_Final_${env.ASSETS_VERSION_NUMBER}.pdf`}
                    download="UserMgmtJobAID"
                    target="_blank"
                    rel="noopener"
                >
                    here
                </a>{' '}
                to download the user management job aid.
            </p>
            <br />
            <br />
        </>
    );
};

export default ResourceAbout;
