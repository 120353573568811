import request, { formatGetOptions } from '../utils/request';
import { BASE_URL_V2 } from './api-service/Url_Constants';
import { responseMapping } from './api-helpers/responseMapping';
import { accountListSchema } from './api-service/maps';

export const listProviderAccounts = async () => {
    const options = formatGetOptions();

    const { payload } = await request(`${BASE_URL_V2}/accounts/provider/accounts/list`, options);

    return responseMapping(accountListSchema, payload);
};

export const getProviders = async (values) => {
    const { orgId } = values.payload;
    const { myadsBrandId } = values.payload;

    const options = formatGetOptions();

    const data = await request(`${BASE_URL_V2}/accounts/provider/orgs/${orgId}/brands/${myadsBrandId}`, options);

    let providerWithCatBrands;

    // eslint-disable-next-line no-restricted-syntax
    for (const provider of data?.payload.providers) {
        if (provider.provider === 'Koddi') {
            const { providerId, providerAccountId, providerAccountName } = provider;
            const { catalogBrands } = data?.payload;

            providerWithCatBrands = {
                providerId,
                providerAccountId,
                providerAccountName,
                catalogBrands,
            };

            return { ...providerWithCatBrands, orgId, myadsBrandId };
        }
    }
    return {};
};
