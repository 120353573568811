/**
 *
 * CreateUserPage
 *
 */

import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { InputLabel, Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { OrganizationSelect } from 'components/UserForm/Organization/OrganizationSelect';
import TextDisabled from 'components/TextDisabled/Loadable';
import { DialogAction, formatSubmitValues } from 'containers/OrganizationPage/utils';
import { ShareCampaignDialogBox } from 'containers/OrganizationPage/OrgFlyouts/ShareCampaignFlyout/ShareCampaignDialogBox';
import { shareOrg } from 'containers/App/actions';
import { useDispatch } from 'react-redux';
import { formatBrands } from 'utils/orgUtils';
import Brand from './Brand';

const useStyles = makeStyles((theme) => ({
    shareCampaignDetails: {
        paddingBottom: '24px',
    },
    inputLabel: {
        height: 14,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        color: theme.palette.techBlack,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 14,
    },
    headline: {
        margin: '0 0 24px 0',
        fontSize: '28px',
        fontFamily: 'HumanBBYDigital, sans-serif',
        paddingLeft: theme.spacing(4),
    },
    content: {
        backgroundColor: 'white',
    },
    formButton: {
        height: 42,
        borderRadius: 4,
        padding: 0,
        marginTop: '24px',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.humanBlue,
        border: 'none !important',
        color: theme.palette.standardWhite,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
    formButtonCancel: {
        backgroundColor: theme.palette.battleGray,
        color: theme.palette.techBlack,
        '&:hover': {
            backgroundColor: theme.palette.borderGray,
        },
    },
    errorStyle: {
        color: theme.palette.valueRed,
        borderColor: `${theme.palette.valueRed}`,
        fontFamily: 'humanBBYDigital',
        height: 14,
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
    },
    dialog: {
        padding: '16px 24px',
    },
    dialogButton: {
        margin: '0',
    },
    toggleButton: {
        marginTop: '8px',
    },
}));

export default function ShareCampaignFlyout({ organization, orgLoadState, brands, orgOptions, closeFlyout }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [submitValues, setSubmitValues] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const onShareCampaignsFormSubmit = (values) => {
        const result = formatSubmitValues(organization, values);
        setSubmitValues(result);
        setDialogOpen(true);
    };

    const parentCompName = 'ShareCampaignFlyout';

    const organizationBrandOptions = useMemo(() => formatBrands(organization?.brands, brands), [
        brands,
        organization?.brands,
    ]);

    const initialValues = useMemo(() => {
        if (submitValues !== null) {
            return {
                organizations: [submitValues.sharedTo],
                brands: submitValues.brands,
            };
        }

        if (organizationBrandOptions.length === 1) {
            return { brands: organizationBrandOptions };
        }
        return {};
    }, [organizationBrandOptions, submitValues]);

    const onSubmitCampaignsProceed = () => {
        onCampaignShareSubmit(submitValues);
        setDialogOpen(false);
        closeFlyout();
    };

    const onSubmitCampaignsCancel = () => {
        setDialogOpen(false);
        setSubmitValues(null);
    };

    const onCampaignShareSubmit = (sharedData) => {
        dispatch(shareOrg(sharedData));
    };

    return (
        <>
            <Form
                initialValues={initialValues}
                autoComplete="off"
                onSubmit={onShareCampaignsFormSubmit}
                render={({ handleSubmit, submitting, hasValidationErrors, pristine }) => (
                    <form onSubmit={handleSubmit} className={classes.content}>
                        <Grid item xs={12} className={classes.shareCampaignDetails}>
                            <InputLabel className={classes.inputLabel}>Organization</InputLabel>
                            <TextDisabled text={organization?.name} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel className={classes.inputLabel}>Brands</InputLabel>
                            <Brand brands={organizationBrandOptions} />
                        </Grid>
                        <Grid item xs={12}>
                            <OrganizationSelect
                                organizations={orgOptions}
                                organizationLoadState={orgLoadState}
                                allowSelect
                                label="Share to Organization"
                                parentComp={parentCompName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                data-testid="submit-user-form-button"
                                className={classes.formButton}
                                type="submit"
                                disabled={
                                    submitting ||
                                    hasValidationErrors ||
                                    // If the user has clicked submit, but cancelled confirmation dialog, they will still be able to
                                    // submit again since pristine value will reset
                                    // onCampaignShareSubmit
                                    pristine ||
                                    dialogOpen
                                }
                            >
                                Share Campaigns
                            </Button>
                        </Grid>
                    </form>
                )}
            />
            <ShareCampaignDialogBox
                open={dialogOpen}
                cancel={onSubmitCampaignsCancel}
                submitValues={submitValues}
                onSubmit={onSubmitCampaignsProceed}
                dialogBoxAction={DialogAction.SHARE}
            />
        </>
    );
}

ShareCampaignFlyout.propTypes = {
    organization: PropTypes.object,
    brands: PropTypes.array,
    orgLoadState: PropTypes.string,
    orgOptions: PropTypes.array,
    closeFlyout: PropTypes.func,
};
