/**
 *
 * AccountListTable
 *
 */
import React, { useMemo } from 'react';
import { dateFormatter } from 'Helpers/table-formatter';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { createStructuredSelector } from 'reselect';
import { FlyoutAction } from 'shared/constants';
import Table from 'components/@shared/Table';
import { updateAccountPageNumber } from '../../slice';
import { selectAccountPageNumber } from '../../selectors';

const accountColumns = {
    providerAccountName: {
        field: 'providerAccountName',
        label: 'Account',
        sortable: true,
    },
    providerAccountId: {
        field: 'providerAccountId',
        label: 'Account ID',
        sortable: true,
    },
    providerName: {
        field: 'providerName',
        label: 'Provider',
        sortable: true,
    },
    brandName: {
        field: 'myadsBrandName',
        label: 'Brand',
        sortable: true,
    },
    providerAccountOrgName: {
        field: 'providerAccountOrgName',
        label: 'Organization',
        sortable: true,
    },
    createdAt: {
        field: 'createdAt',
        label: 'Created On',
        sortable: true,
        format: dateFormatter,
    },
    updatedAt: {
        field: 'updatedAt',
        label: 'Updated On',
        sortable: true,
        format: dateFormatter,
    },
};

function containIgnoreCase(object, fields, skw) {
    if (!skw) {
        return true;
    }
    if (fields) {
        // eslint-disable-next-line no-restricted-syntax
        for (const field of fields) {
            const string = `${object[field]}`;
            if (string && string.toUpperCase().includes(skw.toUpperCase())) {
                return true;
            }
        }
    }
    return false;
}

function AccountListTable({
    accounts,
    setFlyoutStatus,
    setSelectedItem,
    searchKeyword,
    // Redux
    onUpdatePageNumber,
    pageNumber,
}) {
    const filteredAccounts = useMemo(
        () =>
            accounts.filter((account) =>
                containIgnoreCase(
                    account,
                    ['providerAccountName', 'providerBrandName', 'providerAccountOrgName'],
                    searchKeyword,
                ),
            ),
        [accounts, searchKeyword],
    );

    const tableActions = (account) => [
        {
            label: 'Edit Account',
            onClick: () => {
                setSelectedItem(account);
                setFlyoutStatus(FlyoutAction.EDIT_ACCOUNT);
            },
        },
        {
            label: 'Move Account',
            onClick: () => {
                setSelectedItem(account);
                setFlyoutStatus(FlyoutAction.MOVE_ACCOUNT);
            },
        },
    ];

    return (
        <div>
            <Table
                data={filteredAccounts}
                columns={Object.values(accountColumns)}
                actions={tableActions}
                pageNumber={pageNumber}
                sort={{ column: accountColumns.updatedAt, order: 'desc' }}
                onUpdatePageNumber={onUpdatePageNumber}
                keyFn={(item) => item.providerAccountId}
            />
        </div>
    );
}

AccountListTable.propTypes = {
    accounts: PropTypes.arrayOf(
        PropTypes.shape({
            providerAccountId: PropTypes.string.isRequired,
            providerAccountName: PropTypes.string,
            providerName: PropTypes.string,
            providerBrandName: PropTypes.string,
            providerAccountOrgName: PropTypes.string,
            createdAt: PropTypes.string,
            updatedAt: PropTypes.string,
        }),
    ).isRequired,
    onUpdatePageNumber: PropTypes.func,
    pageNumber: PropTypes.number,
    setFlyoutStatus: PropTypes.func,
    setSelectedItem: PropTypes.func,
    searchKeyword: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    pageNumber: selectAccountPageNumber,
});

export function mapDispatchToProps(dispatch) {
    return {
        onUpdatePageNumber: (pageNumber) => {
            dispatch(updateAccountPageNumber(pageNumber));
        },
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AccountListTable);
