import { TextareaAutosize } from '@material-ui/core';
import Button from 'components/Button';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import AlertBanner from 'components/AlertBanner/index';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { loadAlertsAction } from 'layouts/AlertBanner/slice';
import FeatureFlagControl from 'components/@dev-tools/FeatureFlagControl';
import { storeItem } from 'utils/localStorage';
import withAuth from '../../api/api-helpers/with-auth';
import { MOCK_ALERT_KEY, mockAlerts } from './mockAlerts';

function DevPage({ setAlerts }) {
    const history = useHistory();

    const [results, setResults] = useState({
        message: null,
        result: null,
    });
    const [showCopiedMessage, setShowCopiedMessage] = useState(false);

    const onSubmit = async (values) => {
        try {
            const resp = await withAuth.post(
                'https://portal-api-corp-ad-tech-tools-test.test.aws.containers.bestbuy.com/v4/query/raw',
                values.query,
                {
                    headers: {
                        'Content-Type': 'text/plain',
                    },
                },
            );

            const { data, message } = resp.data;
            setShowCopiedMessage(false);
            if (data) {
                setResults({
                    message: `${data.length} Results`,
                    result: data.map((res) => JSON.stringify(res)).join('\n'),
                });
            } else {
                setResults({ message, result: null });
            }
        } catch (e) {
            setShowCopiedMessage(false);
            setResults({ message: e.message, result: null });
        }
    };

    const clipboardCopy = () => {
        navigator.clipboard.writeText(results.result);
        setShowCopiedMessage(true);
    };

    const handleIDAMLogin = () => {
        history.push('/idamlogin');
    };

    const handleIDAMOtpByEmail = () => {
        history.push('/otp/email');
    };

    const handleIDAMOtpByPhone = () => {
        history.push('/otp/phone');
    };

    const handleIDAMThankYou = () => {
        history.push('/idam/thankyou');
    };

    const onClickDismissAlert = (id) => {
        // TODO
        // eslint-disable-next-line no-console
        console.log('onClickDismiss', id);
    };

    const handleSetupAlerts = () => {
        setAlerts(mockAlerts);
        storeItem(MOCK_ALERT_KEY, true);
    };
    const handleClearAlerts = () => {
        setAlerts([]);
        storeItem(MOCK_ALERT_KEY, false);
    };

    const handleCampaignManagementSandbox = () => {
        history.push('/campaigns/sandbox');
    };

    return (
        <div>
            <FeatureFlagControl />
            <div style={{ padding: '3rem' }}>
                <h2>Campaign Management Sandbox</h2>
                <div>
                    <Button type="submit" onClick={handleCampaignManagementSandbox}>
                        Sandbox Page
                    </Button>
                </div>
                <h2>Test IDAM Login</h2>
                <div>
                    <Button type="submit" onClick={handleIDAMLogin}>
                        IDAM Login
                    </Button>
                </div>
                <h2>Test IDAM Invite</h2>
                <div>
                    <Button type="submit" onClick={handleIDAMOtpByEmail}>
                        IDAM OTP by Email
                    </Button>
                </div>
                <div>
                    <Button type="submit" onClick={handleIDAMOtpByPhone}>
                        IDAM OTP by Phone
                    </Button>
                </div>
                <div>
                    <Button type="submit" onClick={handleIDAMThankYou}>
                        IDAM Thank You Page
                    </Button>
                </div>
                <h2>Test Alerts</h2>
                <div>
                    <h4>Mock alerts</h4>
                    <AlertBanner alertBannerData={mockAlerts} dismissAlert={(id) => onClickDismissAlert(id)} />
                </div>
                <div>
                    <Button type="submit" onClick={handleSetupAlerts}>
                        Setup mock alerts
                    </Button>
                    <Button type="submit" onClick={handleClearAlerts}>
                        Clear mock alerts
                    </Button>
                </div>
                <h2>Run SQL Query</h2>
                <Form onSubmit={onSubmit}>
                    {({ handleSubmit }) => (
                        <>
                            <Field name="query">
                                {({ input }) => <TextareaAutosize minRows={3} style={{ minWidth: 1000 }} {...input} />}
                            </Field>
                            <div>
                                <Button type="submit" onClick={handleSubmit}>
                                    Run Query
                                </Button>
                            </div>
                        </>
                    )}
                </Form>
                <div style={{ margin: '3rem' }}>
                    <div style={{ paddingBottom: '0.5rem' }}>
                        {results.message && (
                            <h3 style={{ display: 'inline', marginBlock: '0', verticalAlign: 'bottom' }}>
                                {results.message}
                            </h3>
                        )}
                        {results.result && (
                            <Button type="button" style={{ marginLeft: '1rem' }} onClick={clipboardCopy}>
                                {`${showCopiedMessage ? 'Copied' : 'Copy'} To Clipboard`}
                            </Button>
                        )}
                    </div>
                    {results.result !== null && (
                        <div style={{ padding: '0 0.2rem', overflow: 'scroll', border: 'solid 1px', maxHeight: 500 }}>
                            <pre>
                                <code>{results.result}</code>
                            </pre>
                        </div>
                    )}
                </div>
            </div>
            <hr />
        </div>
    );
}

DevPage.propTypes = {
    setAlerts: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    setAlerts: (alerts) => dispatch(loadAlertsAction(alerts)),
});

const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(DevPage);
