import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PageNotFound from '../../PageNotFound';

const CampaignManagementSandboxPage = () => {
    const showCampaignManagement = useSelector((state) => state.featureFlags.showCampaignManagement);

    if (!showCampaignManagement) {
        return (
            <>
                <PageNotFound />
                <Redirect to="/" />
            </>
        );
    }

    return <div></div>;
};

export default CampaignManagementSandboxPage;
