import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { numberWithCommas } from 'Helpers/string-formatter';
import { formatSubtitle } from 'utils/formatData';
import MetricTabContext from './MetricTabContext';

const useStyles = makeStyles((theme) => ({
    // Container styles
    tabContainer: {
        margin: 0,
        padding: 0,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '&:first-child > $button': {
            // Remove left border from first tab
            borderWidth: '0 0 1px 0',
        },
    },

    // Button styles
    button: {
        backgroundColor: 'transparent',
        cursor: 'pointer',
        borderStyle: 'solid',
        borderColor: theme.palette.borderGrey,
        borderWidth: '0 0 1px 1px',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '10px 15px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.techBlack,
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
            color: theme.palette.standardWhite,
            borderBottomColor: theme.palette.darkBlue,
        },
        '&:hover + $arrow': {
            backgroundColor: theme.palette.darkBlue,
        },
    },
    active: {
        backgroundColor: theme.palette.humanBlue,
        color: theme.palette.standardWhite,
        borderBottomColor: theme.palette.humanBlue,
        '& + $arrow': {
            backgroundColor: theme.palette.humanBlue,
        },
    },

    // Text styles
    textContent: {
        textAlign: 'left',
    },
    title: {
        fontSize: 14,
        fontFamily: 'humanBBYDigital',
    },
    subtitle: {
        fontFamily: 'humanBBYDigital',
        fontSize: '24px',
        fontWeight: 500,
    },
    longNum: {
        display: 'none',
        '@media (min-width: 1000px)': {
            display: 'inline',
        },
    },
    shortNum: {
        display: 'inline',
        '@media (min-width: 1000px)': {
            display: 'none',
        },
    },

    // Arrow styles
    arrow: {
        height: '0.75rem',
        width: '1.5rem',
        clipPath: 'polygon(50% 100%, 0 0, 100% 0)',
    },
    arrowHidden: {
        height: '0.75rem',
        visibility: 'hidden',
    },
    arrowAnimated: {
        height: 0,
        animation: '$extendArrow 0.2s forwards',
    },
    '@keyframes extendArrow': {
        '0%': {
            height: '0',
        },
        '100%': {
            height: '0.75rem',
        },
    },
}));

const MetricTab = ({ tabId, metricName, metricValue, metricUnit, isLoading = false }) => {
    const classes = useStyles();
    const { changeTab, currentTab } = useContext(MetricTabContext);
    const shouldAnimate = useRef(false);
    const isActive = currentTab === tabId;
    let arrowStyles = classes.arrowHidden;

    if (isActive) {
        if (shouldAnimate.current) {
            arrowStyles = classNames(classes.arrow, classes.arrowAnimated);
        } else {
            arrowStyles = classes.arrow;
        }
    }

    const onTabClick = () => {
        // only animate the arrow if tab is clicked, not on page load
        shouldAnimate.current = true;
        changeTab(tabId);
    };

    return (
        <ul className={classes.tabContainer}>
            <button
                type="button"
                className={classNames(classes.button, isActive ? classes.active : undefined)}
                onClick={onTabClick}
            >
                <div className={classes.textContent}>
                    <Typography key="title-Impressions" className={classes.title}>
                        {metricName}
                    </Typography>
                    <Typography key="subtitle-Impressions" className={classes.subtitle}>
                        {isLoading ? (
                            <Skeleton animation="wave" variant="text" width={75} height={35} />
                        ) : (
                            <>
                                <span className={classes.longNum}>{numberWithCommas(metricValue, metricUnit)}</span>
                                <span className={classes.shortNum}>{formatSubtitle(metricValue, metricUnit)}</span>
                            </>
                        )}
                    </Typography>
                </div>
            </button>
            <div className={arrowStyles}></div>
        </ul>
    );
};

MetricTab.propTypes = {
    tabId: PropTypes.string.isRequired,
    metricName: PropTypes.string.isRequired,
    metricValue: PropTypes.number,
    metricUnit: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
};

export { MetricTab };
