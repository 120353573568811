/**
 *
 * SummaryTable
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { RIGHT_ALIGN_FIELDS as rightAlignFields } from 'containers/SummaryDashboardPage/constants';
import { sortCampaignType } from 'containers/SummaryDashboardPage/helpers/helpers';

import Table from 'components/@shared/Table';

const SummaryTable = ({
    items,
    totalItems,
    columns,
    loading,
    summaryDashboardL1,
    actions,
    noRecordsText,
    totalColumns,
    onClick,
}) => (
    <Table
        loading={loading}
        data={sortCampaignType(items)}
        dataTotal={totalItems}
        columns={columns}
        totalColumns={totalColumns}
        noDataText={noRecordsText}
        actions={actions}
        onRowClick={onClick}
        summaryDashboardL1={summaryDashboardL1}
        styledFields={rightAlignFields}
    />
);

SummaryTable.propTypes = {
    items: PropTypes.array,
    totalItems: PropTypes.object,
    columns: PropTypes.array.isRequired,
    totalColumns: PropTypes.array,
    loading: PropTypes.bool,
    actions: PropTypes.func,
    noRecordsText: PropTypes.string,
    onClick: PropTypes.func,
    summaryDashboardL1: PropTypes.bool,
};

SummaryTable.defaultProps = {
    items: [],
};

const withConnect = connect();

export default compose(withConnect, memo)(SummaryTable);
