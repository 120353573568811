import { RequestStatus } from 'shared/constants';
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    organizationLoadState: RequestStatus.INIT,
    updateProviderLoadState: RequestStatus.INIT,
    getProvidersLoadState: RequestStatus.INIT,
    accountLoadState: RequestStatus.INIT,
    orgTypeLoadState: RequestStatus.INIT,
    addOrgLoadState: RequestStatus.INIT,
    addBrandLoadState: RequestStatus.INIT,
    updateOrgLoadState: RequestStatus.INIT,
    addAccountLoadState: RequestStatus.INIT,
    updateBrandLoadState: RequestStatus.INIT,
    moveAccountLoadState: RequestStatus.INIT,
    updateAccountLoadState: RequestStatus.INIT,
    organizationLoadError: null,
    moveAccountLoadError: null,
    organizations: [],
    organizationFilter: {
        keyword: '',
        includes: [
            { name: 'name', label: 'Organizations', checked: true },
            { name: 'brands', label: 'Brands', checked: true },
        ],
    },
    brandLoadState: RequestStatus.INIT,
    brandLoadError: null,
    brands: [],
    accounts: [],
    orgTypes: [],
    providers: {},
    pageNumbers: { organization: 0, brand: 0, account: 0 },
};

const organizationPageSlice = createSlice({
    name: 'organizationPage',
    initialState,
    reducers: {
        loadOrganizationListAction(draft) {
            if (draft.organizationLoadState !== RequestStatus.DONE) {
                draft.organizationLoadState = RequestStatus.LOADING;
            }
        },
        loadOrganizationListSuccessAction(draft, action) {
            draft.organizationLoadState = RequestStatus.DONE;
            draft.organizations = action.payload;
        },
        loadOrganizationListErrorAction(draft, action) {
            draft.organizationLoadState = RequestStatus.ERROR;
            draft.organizationLoadError = action.payload;
        },
        updateFilteredOrganizations(draft, action) {
            draft.organizationFilter = action.payload;
        },
        loadBrandAction(draft) {
            if (draft.brandLoadState !== RequestStatus.DONE) {
                draft.brandLoadState = RequestStatus.LOADING;
            }
        },
        loadBrandSuccessAction(draft, action) {
            draft.brandLoadState = RequestStatus.DONE;
            draft.brands = action.payload;
        },
        loadBrandErrorAction(draft, action) {
            draft.brandLoadState = RequestStatus.ERROR;
            draft.organizationLoadError = action.payload;
        },
        updateOrganizationPageNumber(draft, action) {
            draft.pageNumbers.organization = action.payload;
        },
        updateBrandPageNumber(draft, action) {
            draft.pageNumbers.brand = action.payload;
        },
        updateAccountPageNumber(draft, action) {
            draft.pageNumbers.account = action.payload;
        },
        updateOrganizationAction(draft, action) {
            const newOrgList = draft.organizations.map((org) => {
                if (org.orgId === action.payload.orgId) {
                    return action.payload;
                }
                return org;
            });
            draft.organizations = newOrgList;
        },
        loadProvidersAction(draft) {
            if (draft.getProvidersLoadState !== RequestStatus.DONE) {
                draft.getProvidersLoadState = RequestStatus.LOADING;
            }
        },
        loadProvidersSuccessAction(draft, action) {
            draft.getProvidersLoadState = RequestStatus.DONE;
            if (action.payload?.providerId) {
                draft.providers[action.payload.providerId] = action.payload;
            }
        },
        loadProvidersError(draft, action) {
            draft.getProvidersLoadState = RequestStatus.ERROR;
            draft.getProvidersLoadError = action.payload;
        },
        loadAccountsAction(draft) {
            if (draft.accountLoadState !== RequestStatus.DONE) {
                draft.accountLoadState = RequestStatus.LOADING;
            }
        },
        loadAccountsSuccessAction(draft, action) {
            draft.accountLoadState = RequestStatus.DONE;
            draft.accounts = action.payload;
        },
        loadAccountsErrorAction(draft, action) {
            draft.accountLoadState = RequestStatus.ERROR;
            draft.accountLoadError = action.payload;
        },
        loadOrgTypesAction(draft) {
            if (draft.orgTypeLoadState !== RequestStatus.DONE) {
                draft.orgTypeLoadState = RequestStatus.LOADING;
            }
        },
        loadOrgTypesSuccessAction(draft, action) {
            draft.orgTypeLoadState = RequestStatus.DONE;
            draft.orgTypes = action.payload;
        },
        loadOrgTypesErrorAction(draft, action) {
            draft.orgTypeLoadState = RequestStatus.ERROR;
            draft.orgTypesLoadError = action.payload;
        },
        addBrandAction(draft) {
            if (draft.addBrandLoadState !== RequestStatus.DONE) {
                draft.addBrandLoadState = RequestStatus.LOADING;
            }
        },
        addBrandSuccessAction(draft, action) {
            draft.addBrandLoadState = RequestStatus.DONE;
            draft.brands = (() => {
                const { brands } = draft;
                brands.unshift(action.payload);
                return brands;
            })();
        },
        addBrandErrorAction(draft, action) {
            draft.addBrandLoadState = RequestStatus.ERROR;
            draft.addBrandLoadError = action.payload;
        },
        updateBrandAction(draft) {
            if (draft.updateBrandLoadState !== RequestStatus.DONE) {
                draft.updateBrandLoadState = RequestStatus.LOADING;
            }
        },
        updateBrandSuccessAction(draft, action) {
            draft.updateBrandLoadState = RequestStatus.DONE;
            draft.brands = draft.brands.map((brand) =>
                brand.id === action.payload.id ? { ...brand, ...action.payload } : brand,
            );
        },
        updateBrandErrorAction(draft, action) {
            draft.updateBrandLoadState = RequestStatus.ERROR;
            draft.updateBrandLoadError = action.payload;
        },
        addOrgAction(draft) {
            if (draft.addOrgLoadState !== RequestStatus.DONE) {
                draft.addOrgLoadState = RequestStatus.LOADING;
            }
        },
        addOrgSuccessAction(draft, action) {
            draft.addOrgLoadState = RequestStatus.DONE;
            draft.organizations = (() => {
                const { organizations } = draft;
                organizations.unshift(action.payload);
                return organizations;
            })();
        },
        addOrgErrorAction(draft, action) {
            draft.addOrgLoadState = RequestStatus.ERROR;
            draft.addOrgLoadError = action.payload;
        },
        updateOrgAction(draft) {
            if (draft.updateOrgLoadState !== RequestStatus.DONE) {
                draft.updateOrgLoadState = RequestStatus.LOADING;
            }
        },
        updateOrgSuccessAction(draft, action) {
            draft.updateOrgLoadState = RequestStatus.DONE;
            draft.organizations = draft.organizations.map((org) =>
                org.orgId === action.payload.orgId ? { ...org, ...action.payload } : org,
            );
        },
        updateOrgErrorAction(draft, action) {
            draft.updateOrgLoadState = RequestStatus.ERROR;
            draft.updateOrgLoadError = action.payload;
        },
        addAccountAction(draft) {
            if (draft.addAccountLoadState !== RequestStatus.DONE) {
                draft.addAccountLoadState = RequestStatus.LOADING;
            }
        },
        addAccountSuccessAction(draft, action) {
            draft.addAccountLoadState = RequestStatus.DONE;
            draft.accounts = (() => {
                const { accounts } = draft;
                accounts.unshift(action.payload);
                return accounts;
            })();
        },
        addAccountErrorAction(draft, action) {
            draft.addAccountLoadState = RequestStatus.ERROR;
            draft.addAccountLoadError = action.payload;
        },
        updateAccountAction(draft) {
            if (draft.updateAccountLoadState !== RequestStatus.DONE) {
                draft.updateAccountLoadState = RequestStatus.LOADING;
            }
        },
        updateAccountSuccessAction(draft, action) {
            draft.updateAccountLoadState = RequestStatus.DONE;
            draft.accounts = draft.accounts.map((account) =>
                account.id === action.payload.id ? action.payload : account,
            );
        },
        updateAccountErrorAction(draft, action) {
            draft.updateAccountLoadState = RequestStatus.ERROR;
            draft.updateAccountLoadError = action.payload;
        },
        moveAccountAction(draft) {
            if (draft.moveAccountLoadState !== RequestStatus.DONE) {
                draft.moveAccountLoadState = RequestStatus.LOADING;
            }
        },
        moveAccountSuccessAction(draft, action) {
            draft.moveAccountLoadState = RequestStatus.DONE;
            draft.accounts = draft.accounts.map((account) =>
                account.id === action.payload.id ? action.payload : account,
            );
        },
        moveAccountErrorAction(draft, action) {
            draft.moveAccountLoadState = RequestStatus.ERROR;
            draft.moveAccountLoadError = action.payload;
        },
    },
});

export const {
    loadOrganizationListAction,
    loadOrganizationListErrorAction,
    loadOrganizationListSuccessAction,
    updateFilteredOrganizations,
    loadBrandAction,
    loadBrandSuccessAction,
    loadBrandErrorAction,
    updateOrganizationPageNumber,
    updateBrandPageNumber,
    updateAccountPageNumber,
    updateOrganizationAction,
    loadProvidersAction,
    loadProvidersSuccessAction,
    loadProvidersError,
    loadAccountsAction,
    loadAccountsSuccessAction,
    loadAccountsErrorAction,
    loadOrgTypesAction,
    loadOrgTypesSuccessAction,
    loadOrgTypesErrorAction,
    addOrgAction,
    addOrgSuccessAction,
    addOrgErrorAction,
    addBrandAction,
    addBrandSuccessAction,
    addBrandErrorAction,
    updateBrandAction,
    updateBrandSuccessAction,
    updateBrandErrorAction,
    updateOrgAction,
    updateOrgSuccessAction,
    updateOrgErrorAction,
    addAccountAction,
    updateAccountAction,
    updateAccountSuccessAction,
    updateAccountErrorAction,
    addAccountSuccessAction,
    addAccountErrorAction,
    moveAccountAction,
    moveAccountSuccessAction,
    moveAccountErrorAction,
} = organizationPageSlice.actions;
export default organizationPageSlice.reducer;
