import React from 'react';

export const ResourceSponsoredProducts = () => (
    <>
        <br />
        <br />
        <h3>Search.Ads - Sponsored Products</h3>
        <br />
        <p>
            Increase visibility, increase online conversions and be top of mind with customers as they are shopping on
            BestBuy.com and the Best Buy App with sponsored products.
        </p>
        <br />
        <p>
            <b>Sponsored products are: </b>
            <ul>
                <li>Native ad placements that seamlessly blend into the shopper experience.</li>
                <li>
                    Used to ensure a brand&lsquo;s products are promoted when shoppers search, browse, and navigate
                    onsite to maximize reach and product discovery.
                </li>
                <li>Triggered by product attributes, contextual relevancy, customer behaviors, and budget.</li>
                <li>
                    Simply put, sponsored products allow brands to reach high-intent shoppers to boost sales and
                    increase visibility on BestBuy.com.
                </li>
            </ul>
        </p>
        <br />
        <br />
        <h3>Report Views </h3>
        <br />
        <p>
            <b>Summary Page </b>
            <ul>
                <li>
                    This page shows the daily performance of the campaign and includes line graphs of common KPIs to
                    show trends over time.
                </li>
                <li>
                    This is also where you will see total campaign recaps for the time period the report was pulled.
                </li>
                <li>
                    Most common questions can be answered from this page such as what is the total ROAS, CTR, and what
                    happened on a particular day/report time period.
                </li>
            </ul>
        </p>
        <br />
        <p>
            <b>Product View </b>
            <ul>
                <li>
                    This view shows the performance by SKU including direct click (clicked on SKU and bought same SKU),
                    direct view (viewed SKU and bought same SKU), and related click (clicked on SKU, but bought
                    different SKU from same vendor).
                </li>
                <li>
                    It&lsquo;s recommended to use this view to identify top SKU performers with focus on total units,
                    revenue, and ROAS. Active last 30 also shows if SKUs were on for sponsored products reporting in
                    last 30 days.
                </li>
                <li>
                    Attributes related to the Best Buy Product hierarchy, such as Brand, Department, Class and Subclass
                    are additional columns you may add to reporting using the Modify Columns feature when viewing a
                    campaign.
                </li>
            </ul>
        </p>
        <br />
        <p>
            <b>Keyword View </b>
            <ul>
                <li>
                    This view shows the top 100 keywords by delivered spend. This also provides an average cost per
                    click metric and displays attribution by click, by view, and total (click + view).
                </li>
                <li>
                    You can use this view to understand what keywords people are searching for that match the SKUs
                    active for sponsored products and their relative significance to the campaign. Keep in mind
                    sponsored products only serve if the search matches the reporting SKUs organically on our site.
                </li>
            </ul>
        </p>
        <br />
        <p>
            <b>Placement View </b>
            <ul>
                <li>
                    Placement view shows where on our site are sponsored products listings being served. This includes
                    our Homepage carousel, PDP and cart recommendation engines, search autocomplete, and in-grid browse
                    listings.
                </li>
                <li>
                    This view is useful in understanding which placements are resonating with customers the most. This
                    view also shows click, view, and total (click + view) attribution as well as cost per click (CPC).
                </li>
            </ul>
        </p>
        <br />
        <p>
            <b>Platform View </b>
            <ul>
                <li>
                    Platform View shows performance by our three bestbuy.com platforms: Large View (desktop browsers),
                    mApp (Best Buy mobile app), and Small view (mobile web browsers).
                </li>
                <li>
                    This view is useful in understanding how customers browse and ultimately purchase sponsored products
                    across device types. In general, we expect to see higher click-through rates on app and mobile
                    platforms, but higher ROAS on desktop platform as we observe most customers tend to browse on
                    mobile, but purchase on desktop.
                </li>
            </ul>
        </p>
        <br />
        <p>
            <b>Opportunities View </b>
            <ul>
                <li>
                    The opportunity view is where we can highlight the missed revenue opportunities due to insufficient
                    ad spend per day. This is likely your most helpful view for determining what changes you can make to
                    the campaign and how much incremental ad spend could be utilized to increase revenue and ensure
                    sponsored products are on all day.
                </li>
                <li>
                    Helpful metrics include average capout time which shows when ad spend is exhausted each day and
                    advertising ceases (all campaigns start time is 12:00AM UTC). % Active Traffic shows a similar view
                    but as a percentage of the total days&lsquo; impressions. Missed clicks are forecasted clicks that
                    would have happened after capout time. Missed spend is spend needed to stay live all day. Finally
                    missed revenue is revenue forecasted to have happened after capout time. These metrics are all shown
                    as a total and a daily average.
                </li>
            </ul>
        </p>
        <br />
        <br />
        <h3>Report Glossary </h3>
        <br />
        <p>
            <b>Direct Click Units:</b> The SKU that was in the creative that was clicked is the same as the attributed
            unit that was sold.
        </p>
        <br />
        <p>
            <b>Direct Click Revenue:</b> The SKU that was in the creative that was clicked is the same as the attributed
            revenue for the unit that was sold.
        </p>
        <br />
        <p>
            <b>Direct View Units:</b> The SKU that was in the creative that was viewed is the same as the attributed
            unit that was sold.
        </p>
        <br />
        <p>
            <b>Direct View Revenue:</b> The SKU that was in the creative that was viewed is the same as the attributed
            revenue for the unit that was sold.
        </p>
        <br />
        <p>
            <b>Related Click Units:</b> The SKU that was in the creative that was clicked is the same category as the
            attributed SKU that was sold.
        </p>
        <br />
        <p>
            <b>Related Click Revenue:</b> The SKU that was in the creative that was clicked is the same category as the
            attributed revenue for the SKU that was sold.
        </p>
        <br />
        <p>
            <b>Total Revenue:</b> Click + view attributed revenue (includes same brand, same SKU, same category).
        </p>
        <br />
        <p>
            <b>Total Units:</b> Click + view attributed units sold (includes same brand, same SKU, same category).
        </p>
        <br />
        <p>
            <b>Total ROAS:</b> Total revenue attributed to a Click or View Ad event divided by the spend attributed to
            that placement or creative line item. Available as a dollar amount or percentage.
        </p>
        <br />
        <p>
            <b>CPU: </b>
            Ratio of Ad Spend divided by Total Units sold to indicate Cost Per Unit.
        </p>
        <br />
        <p>
            <b>Active Last 30:</b> Confirmation if a SKU was live or not within the last 30 days denoted by a
            &ldquo;TRUE&rdquo; or &ldquo;FALSE&rdquo;.
        </p>
        <br />
        <p>
            <b>Click Attribution Performance:</b> A Sponsored Products ad is served to a customer, the customer clicks,
            and a SKU within the same brand is purchased.
        </p>
        <br />
        <p>
            <b>Click + View Attribution Performance:</b> Click + View takes everything attributed from Click Attribution
            Performance and adds in View Attribution to those totals. In View Attribution, in order for a unit or
            revenue to be recognized, the purchase made must be of the exact same sponsored products SKU that was
            viewed.
        </p>
        <br />
        <p>
            <b>Large View (LV):</b> Listing on Desktop browsers
        </p>
        <br />
        <p>
            <b>MApp:</b> Listing on Best Buy App
        </p>
        <br />
        <p>
            <b>Small View (SV):</b> Listing on Mobile browsers
        </p>
        <br />
        <p>
            <b>Capout Time:</b> Average time at which daily ad spend hits spending cap and no longer populates sponsored
            listing (time in UTC).
        </p>
        <br />
        <p>
            <b>Number of Active SKUs:</b> Number of SKUs active during reporting time period.
        </p>
        <br />
        <p>
            <b>% of Traffic Active:</b> Average percentage of daily traffic that was presented with sponsored listing
            before capout time.
        </p>
        <br />
        <p>
            <b>Missed Clicks:</b> Estimated missed clicks due to budget capping out.
        </p>
        <br />
        <p>
            <b>Click Opportunity:</b> Estimated total click opportunity for future campaigns (clicks + missed clicks).
        </p>
        <br />
        <p>
            <b>Missed Spend:</b> Estimated missed spend due to missed clicks.
        </p>
        <br />
        <p>
            <b>Spend Opportunity:</b> Estimated total spend opportunity for future campaigns (spend + missed spend).
        </p>
        <br />
        <p>
            <b>Missed Revenue:</b> Estimated missed revenue due to missed clicks.
        </p>
        <br />
        <p>
            <b>Revenue Opportunity:</b> Estimated total revenue opportunity for future campaigns (revenue + missed
            revenue).
        </p>
        <br />
        <p>
            <b>Home page and Discovery:</b> Homepage Carousel
        </p>
        <br />
        <p>
            <b>Search and Browse:</b> 3 SKU Banner, In-Grid (Search and Browse) and Search Autocomplete
        </p>
        <br />
        <p>
            <b>Product Details and Cart:</b> PDP Carousel, Rec Engine integration
        </p>
        <br />
        <br />
        <h3>Attribution </h3>
        <br />
        <p>
            <ul>
                <li>
                    Attribution numbers will continue to update post campaign end date to allow for settling of
                    information.
                </li>
                <li>
                    Click attribution has a 14-day lookback window during campaign delivery while view ad events have a
                    24-hour lookback window during delivery, and post campaign end date. As a reminder, only online
                    sales attribution is available currently for Sponsored Products.
                </li>
                <li>
                    We do not currently have in store attribution as we are unable to match our first party customer
                    identifiers and to the ad event ID in our own attribution model currently. We are optimistic that
                    this is something we could unlock at a future date through technological enhancements.
                </li>
            </ul>
        </p>
        <br />
        <br />
        <h3>Reporting FAQs </h3>
        <br />
        <p>
            <b>For what timeframe can I see reporting? </b>
            <br />
            Sponsored Products data is available from August 1, 2021 and onward.
        </p>
        <br />
        <p>
            <b>Why do we only show the top 100 keywords? Can we see all keywords? </b>
            <br />
            Reporting purposely limits keywords to top 100 to focus on meaningful results. Keywords outside of the top
            100 have little to no impact to campaign performance and should not be the focus of our review.
        </p>
        <br />
        <p>
            <b>What metrics align to what goals? </b>
            <br />
            <ul>
                <li>Awareness = Impressions </li>
                <li>Consideration = Clicks, CTR </li>
                <li>Conversion = Revenue, Units, ROAS, CPC, CPU</li>
            </ul>
        </p>
        <br />
        <p>
            <b>Can I see other metrics not listed in reporting? </b>
            <br />
            Reporting provides all available metrics. Many additional metrics can be calculated manually by using the
            metrics available in the existing reports.
        </p>
        <br />
        <p>
            <b>Can I sum transactional data across different campaign types? </b>
            <br />
            No, the same transactional data may be accounted for across multiple campaign types. Summing this data may
            result in inaccurate representation of campaign performance.
        </p>
        <br />
        <p>
            <b>When will out-of-stock product stop serving in sponsored products? </b>
            <br />
            The Best Buy API feeds our Sponsored Products platform with current in stock information. Once the API is
            updated to out of stock the Sponsored Product SKU will no longer serve.
        </p>
        <br />
        <p>
            <b>
                My Sponsored Product showed up this morning when I was searching terms, but why won&lsquo;t they show up
                now?
            </b>
            <br />
            Multiple sponsored product campaigns may be competing for the same keywords resulting in different sponsored
            products to show at different times of the day as the platform attempts to optimize products that are most
            likely to be clicked. Campaign budgets may also cap-out later in the day meaning you will no longer see your
            products after your campaign budget has capped out for the day.
        </p>
        <br />
        <p>
            <b>What CTR or ROAS can we expect to see from our campaign? </b>
            <br />
            Each campaign&lsquo;s performance can vary due to a variety of factors. Here are some things to consider:
            <br />
            <br />
            <ol>
                <li>
                    <b>Dates the campaign is live</b>
                    <br />
                    Secondary holiday timeframes (Memorial Day, Labor Day, etc.), or product launch dates, for example,
                    may impact campaign performance.
                </li>
                <br />
                <li>
                    <b>Advertised SKUs selected</b>
                    <br />

                    <ul>
                        <li>Fewer SKUs will typically result in lower ROAS.</li>
                        <li>Choosing popular SKUs for sponsored products will typically result in higher ROAS.</li>
                    </ul>
                </li>
                <br />
                <li>
                    <b>A sponsored products of reporting SKUs </b>
                    <br />
                    Lower priced SKUs will typically result in lower ROAS as CPC is same regardless of SKU advertised.
                </li>
                <br />
                <li>
                    <b>Size of potential purchaser audience of products </b>
                    <br />
                    Accessory SKUs typically require ownership of specific hardware to use them which may result in
                    lower ROAS and CTR, i.e., you need a certain DSLR camera to use a certain lens, or an iPhone to use
                    an iPhone case.
                </li>
            </ol>
        </p>
        <br />
        <br />
    </>
);
