/**
 *
 * SummaryDashboardChart
 *
 */

import React, { useState } from 'react';
import { Card as MaterialCard } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import { MetricTabBar, MetricTab } from 'components/MetricTabBar';
import PropTypes from 'prop-types';
import DetailsChart from '../Chart/DetailsChart';
import SummaryChartStyles from './SummaryChartStyles.json';
import SummaryDashboardCards from '../SummaryDashboardCards';

const useStyles = makeStyles(() => ({
    chartContainer: {
        padding: '0 24px 24px 24px',
    },
    skeletonChartContainer: {
        padding: '1em',
    },
}));

export default function SummaryChartWrapper({
    cardData,
    visualizationOptions,
    vehicleLabel,
    isLoading,
    chartData,
    cardsLoading,
    showCards = false,
}) {
    const [category, setCategory] = useState('impressions');
    const classes = useStyles();

    return (
        <MaterialCard>
            <>
                <MetricTabBar currentTab={category} onChange={setCategory}>
                    {visualizationOptions.map((option) => (
                        <MetricTab
                            tabId={option.value}
                            metricName={option.title}
                            metricValue={chartData?.totals[option.value]}
                            metricUnit={option.unit}
                            isLoading={isLoading}
                            key={option.value}
                        />
                    ))}
                </MetricTabBar>
                {isLoading ? (
                    <div className={classes.skeletonChartContainer}>
                        <Skeleton animation="wave" variant="rect" width="90%" height={500} style={{ margin: 'auto' }} />
                    </div>
                ) : (
                    <>
                        {showCards && (
                            <SummaryDashboardCards
                                summaryData={cardData}
                                category={category}
                                visualOptions={visualizationOptions}
                                vehicleLabel={vehicleLabel}
                                isLoading={cardsLoading}
                            />
                        )}
                        <div className={classes.chartContainer}>
                            <DetailsChart
                                data={chartData}
                                category={category}
                                loading={isLoading}
                                width={1200}
                                padding={{ left: 75, right: 75, top: 55, bottom: 55 }}
                                styles={SummaryChartStyles}
                                includeYears={false}
                            />
                        </div>
                    </>
                )}
            </>
        </MaterialCard>
    );
}

SummaryChartWrapper.propTypes = {
    chartData: PropTypes.object,
    cardData: PropTypes.array,
    visualizationOptions: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            value: PropTypes.string,
            unit: PropTypes.string,
        }),
    ),
    vehicleLabel: PropTypes.string,
    isLoading: PropTypes.bool,
    cardsLoading: PropTypes.bool,
    showCards: PropTypes.bool,
};
