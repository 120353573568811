import React from 'react';
import { RequestStatus } from '../../shared/constants';
import { FlyoutContent } from '../../components/FlyoutModal';
import TextDisabled from '../../components/TextDisabled';

export const DialogAction = {
    SHARE: 'share',
    UNSHARE: 'unshare',
};

export const formatSubmitValues = (organization, values) => {
    const { organizations, brands } = values;

    return {
        sharedFrom: organization,
        sharedTo: organizations[0],
        brands,
    };
};

export const findDuplicateFormValues = (initialValues, array, objectKey, errorFormValue, errorArrayLabel) => (
    formValue,
) => {
    if (formValue !== initialValues[objectKey] && array.some((arrayObject) => arrayObject[objectKey] === formValue)) {
        return `${errorArrayLabel} ${errorFormValue} already exists.`;
    }
    return undefined;
};

export const advertiserIdJsx = (getProvidersLoadState, advertiserIdValue) => {
    if (advertiserIdValue && getProvidersLoadState === RequestStatus.DONE) {
        return (
            <FlyoutContent label="Advertiser ID">
                <TextDisabled name="advertiserId" id="advertiserId" text={advertiserIdValue} />
            </FlyoutContent>
        );
    }
    if (getProvidersLoadState === RequestStatus.LOADING) {
        return (
            <FlyoutContent label="Advertiser ID">
                <TextDisabled name="advertiserId" id="advertiserId" text="Advertiser ID is loading..." />
            </FlyoutContent>
        );
    }
    return (
        <FlyoutContent label="Advertiser ID">
            <TextDisabled name="advertiserId" id="advertiserId" text="No Advertiser ID." />
        </FlyoutContent>
    );
};
