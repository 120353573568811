import { CREATE_USER_SUCCESS, UPDATE_USER_SUCCESS } from 'containers/App/constants';
import { reinviteIdamUserSuccess } from 'containers/IDAMUserManagement/ReInviteUserContent/actions';
import {
    INVITE_STATUS_TYPE_ACCEPTED,
    INVITE_STATUS_TYPE_COMPLETED,
    INVITE_STATUS_TYPE_EMAIL_OTP_ACCEPTED,
    INVITE_STATUS_TYPE_EMAIL_OTP_EXPIRED,
    INVITE_STATUS_TYPE_EMAIL_OTP_SENT,
    INVITE_STATUS_TYPE_INVITE_EXPIRED,
    INVITE_STATUS_TYPE_INVITE_PROFILE_UPDATED,
    INVITE_STATUS_TYPE_PASSWORD_CHANGE_OTP_ACCEPTED,
    INVITE_STATUS_TYPE_PASSWORD_CHANGE_OTP_EXPIRED,
    INVITE_STATUS_TYPE_PASSWORD_CHANGE_OTP_SENT,
    INVITE_STATUS_TYPE_PASSWORD_UPDATED,
    INVITE_STATUS_TYPE_PHONE_OTP_ACCEPTED,
    INVITE_STATUS_TYPE_PHONE_OTP_EXPIRED,
    INVITE_STATUS_TYPE_PHONE_OTP_SENT,
    INVITE_STATUS_TYPE_PROFILE_UPDATED,
    INVITE_STATUS_TYPE_RESENT,
    INVITE_STATUS_TYPE_SENT,
    INVITE_STATUS_TYPE_USER_CREATED,
    INVITE_STATUS_TYPE_USER_UNPAIRED,
} from '../shared/constants/user/invite-state';
import {
    USER_STATUS_ACTIVE,
    USER_STATUS_ARCHIVED,
    USER_STATUS_INACTIVE,
    USER_STATUS_UNKNOWN,
    UserStatusDisplayInfo,
} from '../shared/constants/user/user-status';

export function getUserStatusDisplayInfo(userStatus) {
    switch (userStatus) {
        case INVITE_STATUS_TYPE_SENT:
        case INVITE_STATUS_TYPE_USER_CREATED:
        case INVITE_STATUS_TYPE_RESENT:
            return UserStatusDisplayInfo.inviteSent;

        case INVITE_STATUS_TYPE_ACCEPTED:
        case INVITE_STATUS_TYPE_EMAIL_OTP_SENT:
        case INVITE_STATUS_TYPE_PHONE_OTP_SENT:
        case INVITE_STATUS_TYPE_EMAIL_OTP_ACCEPTED:
        case INVITE_STATUS_TYPE_PHONE_OTP_ACCEPTED:
        case INVITE_STATUS_TYPE_INVITE_PROFILE_UPDATED:
            return UserStatusDisplayInfo.registrationInProgress;

        case INVITE_STATUS_TYPE_PHONE_OTP_EXPIRED:
        case INVITE_STATUS_TYPE_EMAIL_OTP_EXPIRED:
            return UserStatusDisplayInfo.codeExpired;

        case INVITE_STATUS_TYPE_INVITE_EXPIRED:
        case INVITE_STATUS_TYPE_USER_UNPAIRED:
            return UserStatusDisplayInfo.inviteExpired;

        case INVITE_STATUS_TYPE_PROFILE_UPDATED:
        case INVITE_STATUS_TYPE_PASSWORD_CHANGE_OTP_SENT:
        case INVITE_STATUS_TYPE_PASSWORD_CHANGE_OTP_ACCEPTED:
        case INVITE_STATUS_TYPE_PASSWORD_CHANGE_OTP_EXPIRED:
        case INVITE_STATUS_TYPE_PASSWORD_UPDATED:
        case INVITE_STATUS_TYPE_COMPLETED:
        case USER_STATUS_ACTIVE:
            return UserStatusDisplayInfo.active;

        case USER_STATUS_INACTIVE:
            return UserStatusDisplayInfo.inactive;

        case USER_STATUS_ARCHIVED:
            return UserStatusDisplayInfo.archived;

        case USER_STATUS_UNKNOWN:
        default:
            return UserStatusDisplayInfo.unknown;
    }
}

/**
 * getNewUserStatus - used to retrieve or infer the new user status after performing an action on a user
 * @param {string} actionType
 * @param {object} newUserInfo
 * @param {string} prevStatus
 * @returns string
 */
export function getNewUserStatus(actionType, newUserInfo, prevStatus = USER_STATUS_UNKNOWN) {
    if (
        newUserInfo.user_status &&
        getUserStatusDisplayInfo(newUserInfo.user_status) !== UserStatusDisplayInfo.unknown
    ) {
        return newUserInfo.user_status;
    }

    // If the backend does not provide a user_status, we attempt to infer it

    if (actionType === reinviteIdamUserSuccess().type) {
        return INVITE_STATUS_TYPE_RESENT;
    }

    if (actionType === CREATE_USER_SUCCESS) {
        return newUserInfo.isExtUser ? INVITE_STATUS_TYPE_SENT : USER_STATUS_ACTIVE;
    }

    if (actionType === UPDATE_USER_SUCCESS) {
        if (prevStatus === USER_STATUS_ARCHIVED && !newUserInfo.deleted) {
            return newUserInfo.isExtUser ? INVITE_STATUS_TYPE_RESENT : USER_STATUS_ACTIVE;
        }

        if (!newUserInfo.active) {
            return USER_STATUS_INACTIVE;
        }

        if (prevStatus === USER_STATUS_INACTIVE && newUserInfo.active) {
            // We don't know an external user's previous status before they were set to inactive
            return newUserInfo.isExtUser ? USER_STATUS_UNKNOWN : USER_STATUS_ACTIVE;
        }

        return prevStatus;
    }

    if (newUserInfo.deleted) {
        return USER_STATUS_ARCHIVED;
    }

    return USER_STATUS_UNKNOWN;
}
