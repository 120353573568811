import { isNumber } from 'lodash';
import { nFormatter } from '../Helpers/string-formatter';

export function removeTrailingZeros(number) {
    const numberString = number.toString();

    if (numberString.includes('.')) {
        const splitAtDecimal = numberString.split('.');
        splitAtDecimal[0] = splitAtDecimal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        if (splitAtDecimal[1] === '00') {
            return splitAtDecimal[0];
        }
        return splitAtDecimal.join('.');
    }
    return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// This function will add commas to any size number and will account for decimal places

export function addCommasToNumber(number) {
    if (isNumber(number)) {
        // check if number is an integer, this includes zero
        let numberToFormat;
        if (Number.isInteger(number)) {
            numberToFormat = number;
            // else parse float to two decimal places
        } else {
            numberToFormat = parseFloat(number).toFixed(2);
        }

        return removeTrailingZeros(numberToFormat);
    }
    return '';
}

// This function adds the appropriate symbol to the data string according to the unit
export function addSymbolToData(number, unit) {
    if (number !== undefined && number !== null) {
        if (unit === 'percent') {
            return `${number}%`;
        }

        if (unit === 'currency') {
            return `$${number}`;
        }

        return `${number}`;
    }

    return '';
}

// This function will format data based on type and in rounded form
// It accepts a number value and a unit type
export const formatSubtitle = (value, unit) => {
    const formattedNumber = nFormatter(value, 2);

    return addSymbolToData(formattedNumber, unit);
};
