import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import DialogBox from 'components/DialogBox';
import { addBrandAction } from 'containers/OrganizationPage/slice';
import AddEditBrandForm from '../Shared/AddEditBrandForm';

export default function AddBrandFlyout({ closeFlyout, brands }) {
    const dispatch = useDispatch();
    const [submitValues, setSubmitValues] = useState(null);

    const onSubmit = (values) => {
        setSubmitValues(values);
    };

    const initialValues = {
        name: '',
    };

    const onAddBrandProceed = () => {
        dispatch(addBrandAction(submitValues));
        closeFlyout();
    };

    const onAddBrandCancel = () => {
        setSubmitValues(null);
    };

    return (
        <>
            <AddEditBrandForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                submitDisabled={submitValues !== null}
                brands={brands}
                isEdit={false}
            />
            <DialogBox
                open={submitValues !== null}
                onConfirm={onAddBrandProceed}
                onCancel={onAddBrandCancel}
                title="Create Brand Confirmation"
                message="Are you sure you want to create a new brand?"
                confirmText="Yes, Create Brand"
            />
        </>
    );
}

AddBrandFlyout.propTypes = {
    closeFlyout: PropTypes.func,
    brands: PropTypes.arrayOf(PropTypes.object),
};
