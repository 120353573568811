import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import { Button, Grid, InputLabel, FormControl } from '@material-ui/core';
import Input from 'components/Input';
import {
    required,
    isNotBlank,
    isNotWhitespace,
    minLengthNoWhitespace,
    composeValidators,
    maxLength,
} from 'utils/validators';
import { VALIDATE_ON_MODIFIED } from 'utils/constants';
import RadioSelect from '../../../../components/RadioSelect';

const PLACEHOLDER = 'Select Organization Type';

const useStyles = makeStyles((theme) => ({
    submitButton: {
        height: 42,
        borderRadius: 4,
        padding: 0,
        marginBottom: '24px',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.humanBlue,
        border: 'none !important',
        color: theme.palette.standardWhite,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
        '&:hover:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
    inputLabel: {
        height: 14,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        color: theme.palette.techBlack,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 14,
    },
}));

function isNotDuplicateOrg(initialValues, orgs) {
    return (orgName) => {
        if (orgName !== initialValues.name && orgs.some((b) => b.name === orgName)) {
            return 'Organization name already exists.';
        }
        return undefined;
    };
}

function AddAndEditOrgForm({ orgTypes, onSubmit, submitDisabled, initialValues, organizations, isEdit }) {
    const classes = useStyles();

    return (
        <>
            <Form
                initialValues={initialValues}
                keepDirtyOnReinitialize
                onSubmit={onSubmit}
                render={({ handleSubmit, submitting, pristine, hasValidationErrors }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid item xs={12}>
                            <Field
                                name="name"
                                component="input"
                                type="text"
                                validate={composeValidators(
                                    required,
                                    isNotBlank,
                                    isNotWhitespace,
                                    minLengthNoWhitespace(2),
                                    maxLength(50),
                                    isNotDuplicateOrg(initialValues, organizations),
                                )}
                            >
                                {(props) => (
                                    <Input
                                        inputLabel="Organization Name"
                                        placeholder="Enter Organization Name"
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        inputProps={{
                                            'data-testid': 'add-organization-name-input',
                                            'aria-label': 'organizationName',
                                            maxLength: 255,
                                            id: 'add-org-form-organization-name',
                                        }}
                                        validateOn={VALIDATE_ON_MODIFIED}
                                        {...props}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Field
                            name="type"
                            component="select"
                            type="text"
                            validate={composeValidators(required, isNotBlank)}
                        >
                            {({ input, meta }) => (
                                <Grid item xs={12}>
                                    <InputLabel className={classes.inputLabel}>Select Organization Type</InputLabel>
                                    <div>
                                        <FormControl margin="dense" variant="outlined" fullWidth>
                                            <RadioSelect
                                                {...input}
                                                placeholder={PLACEHOLDER}
                                                value={input.value}
                                                options={orgTypes}
                                                onBlur={(event) => input.onBlur(event)}
                                                onChange={(newValue) => {
                                                    input.onChange(newValue);
                                                }}
                                                noOptionsText="No Options available"
                                            />
                                        </FormControl>
                                        {meta.error && meta.submitFailed && (
                                            <span className={classes.error}>Please select an organization type.</span>
                                        )}
                                    </div>
                                </Grid>
                            )}
                        </Field>
                        <Button
                            data-testid="submit-add-edit-org-form-button"
                            className={classes.submitButton}
                            type="submit"
                            disabled={submitting || hasValidationErrors || pristine || submitDisabled}
                        >
                            {isEdit ? 'Update' : 'Create Organization'}
                        </Button>
                    </form>
                )}
            />
        </>
    );
}

AddAndEditOrgForm.propTypes = {
    props: PropTypes.shape({
        options: PropTypes.array,
    }),
    orgTypes: PropTypes.array,
    createOrg: PropTypes.func,
    onSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    organizations: PropTypes.array,
    submitDisabled: PropTypes.bool,
    isEdit: PropTypes.bool,
};

export default AddAndEditOrgForm;
