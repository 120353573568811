import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useAuthedUser } from 'shared/hooks/useAuthedUser';
import { compact } from 'lodash';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { createStructuredSelector } from 'reselect';
import { makeStyles } from '@material-ui/core/styles';
import { useRequest } from 'shared/hooks/use-request';
import { fetchSummaryCampaignList, fetchSummaryL2Chart } from 'api/api-client';
import { makeSelectCampaignFilters } from 'containers/App/selectors';
import { selectBrands } from '../OrganizationPage/selectors';
import Table from '../../components/@shared/Table';
import TitleBar from '../../components/TitleBar';
import { campaignTypeSummaryColumns } from './CampaignTypeColumnDefinitions';
import { Vehicle } from '../../vehicles';
import { isStackableType } from '../../Helpers/helpers';
import { addCampaignsToWatch } from '../StackedCampaignsPage/WatchCampaignsTab/actions';
import { addCampaignsToGroup } from '../StackedCampaignsPage/actions';
import { makeSelectAuthedUser } from '../App/selectors';
import { makeSelectCampaignGroups } from '../StackedCampaignsPage/selectors';
import { makeSelectCampaignWatchlists } from '../StackedCampaignsPage/WatchCampaignsTab/selectors';
import { tableActionService } from '../../services/tableActionService';
import useFeatureFlags from '../../utils/featureFlags/useFeatureFlags';
import { selectDateFilter, selectCurrentFilter, selectLvlTwoSortOrder } from '../SummaryDashboardPage/selectors';
import { getFilters, addFilter, updateFilter } from '../HomePage/actions';
import { updateSortOrderLvlTwo } from '../SummaryDashboardPage/actions';
import SummaryChartWrapper from '../../components/SummaryDashboardChart/SummaryChartWrapper';
import { VISUALIZATION_OPTIONS_L2, DEFAULT_FILTER_NAME } from '../SummaryDashboardPage/constants';
import { downloadReportStart } from '../CampaignReportingPage/slice';
import { optimizeDatesForReport } from '../../utils/dates';

const useStyles = makeStyles((theme) => ({
    chartWrapper: {
        fontStyle: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        marginBottom: 40,
    },
}));

const SummaryCampaignTypePage = ({
    match,
    user,
    campaignGroups,
    watchlists,
    onAddCampaignsToGroup,
    onAddCampaignsToWatch,
    summaryDashboardFilters,
    onDownloadStart,
    dateFilter,
    sortOrder,
    onSortOrderChange,
    onFilterAdd,
    onFilterSubmit,
    filterMetadata,
    summaryFilterName,
    brands,
    fetchFilters,
}) => {
    const vehicle = Vehicle.getFromKey(match.params.campaignType);

    const classes = useStyles();
    const history = useHistory();
    const featureFlags = useFeatureFlags();
    const campaignTypeLabel = vehicle.getLabel();
    const canDownload = vehicle.hasDownloadPermissions(user);
    const authedUser = useAuthedUser();

    const [fetchSummary, summaryOverviewRequest] = useRequest(fetchSummaryCampaignList);
    const [fetchChart, summaryChartRequest] = useRequest(fetchSummaryL2Chart);

    const { startDate, endDate } = dateFilter;
    const campaignType = vehicle.getCampaignType();

    const onSummaryFilterChange = (filter) => {
        if (!summaryFilterName) {
            onFilterAdd(user.email, DEFAULT_FILTER_NAME, filter);
        } else {
            onFilterSubmit(user.email, DEFAULT_FILTER_NAME, filter);
        }
    };

    useEffect(() => {
        if (authedUser?.email) {
            fetchFilters(authedUser.email);
        }
    }, [fetchFilters, authedUser?.email]);

    useEffect(() => {
        fetchSummary(startDate, endDate, campaignType, summaryDashboardFilters);
        fetchChart(startDate, endDate, campaignType, summaryDashboardFilters);
    }, [startDate, endDate, campaignType, fetchSummary, fetchChart, summaryDashboardFilters]);

    const downloadReport = (dataItem) => {
        const dates = optimizeDatesForReport(dateFilter.startDate, dateFilter.endDate, dataItem);
        onDownloadStart({ ...dataItem, ids: [dataItem.id] }, dates.startDate, dates.endDate, false, false);
    };

    const navTo = (dataItem) => {
        history.push({
            pathname: `/campaigns/details/${dataItem.id}`,
        });
    };

    const tableActions = (dataItem) =>
        compact([
            {
                label: 'View Report',
                onClick: () => {
                    history.push({
                        pathname: `/campaigns/details/${dataItem.id}`,
                    });
                },
            },
            tableActionService.isInWatchList(dataItem.id, watchlists)
                ? {
                      label: 'Watched',
                  }
                : {
                      label: 'Watch',
                      onClick: () => tableActionService.onAddToWatch(dataItem.id, user, onAddCampaignsToWatch),
                  },
            isStackableType(dataItem, featureFlags) &&
                (tableActionService.isInBuildReportGroup(dataItem.id, campaignGroups, user)
                    ? {
                          label: 'Added to Stack',
                      }
                    : {
                          label: 'Add to Stack',
                          onClick: () =>
                              tableActionService.onAddToStack(dataItem.id, dataItem, user, onAddCampaignsToGroup),
                      }),
            canDownload
                ? {
                      label: 'Download Report',
                      onClick: () => downloadReport({ ...dataItem, customFileName: dataItem.name }, dateFilter),
                  }
                : {},
        ]);

    return (
        <div>
            <TitleBar
                title={`${campaignTypeLabel} Summary`}
                onSummaryFilterChange={onSummaryFilterChange}
                currentSummaryFilter={summaryDashboardFilters}
                campaignType={vehicle.getCampaignType()}
                filterMetadata={filterMetadata}
                brandData={brands}
                dateFilter={dateFilter}
                backRoute="/dashboard"
            />
            <div className={classes.chartWrapper}>
                <SummaryChartWrapper
                    chartData={summaryChartRequest.data}
                    isLoading={summaryChartRequest.isInit || summaryChartRequest.isLoading}
                    visualizationOptions={VISUALIZATION_OPTIONS_L2}
                    vehicleLabel={vehicle.getLabel()}
                />
            </div>
            <Table
                loading={summaryOverviewRequest.isInit || summaryOverviewRequest.isLoading}
                data={summaryOverviewRequest.data?.campaigns}
                dataTotal={summaryOverviewRequest.data?.totals}
                columns={campaignTypeSummaryColumns}
                actions={tableActions}
                sort={sortOrder}
                onSortOrderChange={onSortOrderChange}
                onRowClick={navTo}
            />
        </div>
    );
};

SummaryCampaignTypePage.propTypes = {
    match: PropTypes.object,
    summaryDashboardFilters: PropTypes.object,
    user: PropTypes.shape({
        uuid: PropTypes.string,
        created_at: PropTypes.string,
        email: PropTypes.string,
        role: PropTypes.string,
    }),
    campaignGroups: PropTypes.array.isRequired,
    watchlists: PropTypes.array,
    onAddCampaignsToWatch: PropTypes.func.isRequired,
    onAddCampaignsToGroup: PropTypes.func.isRequired,
    onDownloadStart: PropTypes.func,
    download: PropTypes.shape({
        start: PropTypes.func,
        loading: PropTypes.bool,
        error: PropTypes.bool,
    }),
    dateFilter: PropTypes.shape({
        startDate: PropTypes.string,
        endDate: PropTypes.string,
    }),
    sortOrder: PropTypes.shape({
        column: PropTypes.object,
        order: PropTypes.string,
    }),
    onSortOrderChange: PropTypes.func,
    onFilterAdd: PropTypes.func,
    onFilterSubmit: PropTypes.func,
    filterMetadata: PropTypes.shape({
        brand_name: PropTypes.object,
        category_groups: PropTypes.object,
    }),
    summaryFilterName: PropTypes.string,
    brands: PropTypes.array,
    fetchFilters: PropTypes.func,
};

SummaryCampaignTypePage.defaultProps = {
    dateFilter: {
        endDate: moment().format('YYYY-MM-DD'),
        startDate: moment().subtract(30, 'day').format('YYYY-MM-DD'),
    },
};

const mapStateToProps = createStructuredSelector({
    user: makeSelectAuthedUser(),
    campaignGroups: makeSelectCampaignGroups(),
    watchlists: makeSelectCampaignWatchlists(),
    dateFilter: selectDateFilter,
    summaryDashboardFilters: selectCurrentFilter,
    sortOrder: selectLvlTwoSortOrder,
    brands: selectBrands,
    filterMetadata: makeSelectCampaignFilters(),
});

export function mapDispatchToProps(dispatch) {
    return {
        onAddCampaignsToGroup: (userId, groupName, groupType, campaignIds) =>
            dispatch(addCampaignsToGroup(userId, groupName, groupType, campaignIds)),
        onAddCampaignsToWatch: (userId, watchName, campaignIds) =>
            dispatch(addCampaignsToWatch(userId, watchName, campaignIds)),
        onDownloadStart: (campaign, startDate, endDate, viewability, raw) =>
            dispatch(downloadReportStart({ campaign, startDate, endDate, viewability, raw })),
        fetchFilters: (userId) => dispatch(getFilters(userId)),
        onSortOrderChange: (column, order) => dispatch(updateSortOrderLvlTwo(column, order)),
        onFilterAdd: (userId, filterName, filter) => dispatch(addFilter(userId, filterName, filter)),
        onFilterSubmit: (userId, filterName, filter) => dispatch(updateFilter(userId, filterName, filter)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SummaryCampaignTypePage);
