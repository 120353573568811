/*
In addition to the below statuses, the user_status field of the user object can return any of
the statuses found in invite-state.js
*/

export const USER_STATUS_ACTIVE = 'active';
export const USER_STATUS_INACTIVE = 'inactive';
export const USER_STATUS_ARCHIVED = 'archived';
export const USER_STATUS_UNKNOWN = 'unknown';

export const UserStatusDisplayInfo = {
    inviteSent: {
        text: 'Invite Sent',
        showReinvite: true,
        description:
            "The registration email is in the user's inbox but the getting started link has not yet been clicked",
    },

    inviteExpired: {
        text: 'Invite Expired',
        showReinvite: true,
        description: 'The invite link has expired before a user could complete the registration process',
    },

    codeExpired: {
        text: 'Code Expired',
        showReinvite: true,
        description: 'The OTP code has expired',
    },

    registrationInProgress: {
        text: 'Registration in progress',
        showReinvite: true,
        description:
            'The invited users has at least click "Getting Started" in the registration email, and coiuld be in any subsequent point in the process',
    },

    active: {
        text: 'Active',
        showReinvite: false,
        description: 'A user has completed the registration process and has access to My Ads',
    },

    inactive: {
        text: 'Inactive',
        showReinvite: false,
        description: 'A user is inactive and does not have access to My Ads',
    },

    archived: {
        text: 'Archived',
        showReinvite: false,
        description: 'A user has been archived',
    },

    unknown: {
        text: 'Unknown',
        showReinvite: true,
        description: 'Unknown user status',
    },
};
