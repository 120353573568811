import { campaignTypeOrder } from 'shared/constants/vehicle';

export function sortCampaignType(items) {
    const result = items.sort((a, b) => {
        const indexA = campaignTypeOrder.indexOf(a.campaignType);
        const indexB = campaignTypeOrder.indexOf(b.campaignType);

        if (indexA === -1) {
            return 1;
        }
        if (indexB === -1) {
            return -1;
        }

        return indexA - indexB;
    });

    return result;
}

export const getBrandIds = (brandData, filteredBrands) => {
    const brandsWithIds = brandData?.filter((brand) => filteredBrands?.find((name) => brand.name === name));

    return brandsWithIds?.map((brand) => brand.id);
};
