/**
 *
 * BackButton
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
    filterButton: {
        backgroundColor: 'white',
        border: `1px solid ${theme.palette.borderGray}`,
        borderRadius: '50%',
        height: '46px',
        width: '46px',
        marginRight: '16px',
        '&:hover': {
            backgroundColor: theme.palette.humanBlue,
            color: 'white',
        },
    },
}));

const BackButton = ({ backRoute }) => {
    const classes = useStyles();
    const history = useHistory();
    const navTo = () => {
        history.push({
            pathname: backRoute,
        });
    };

    return (
        <IconButton data-testid="backButton" onClick={navTo} className={classes.filterButton}>
            <ArrowBackIcon />
        </IconButton>
    );
};

BackButton.propTypes = {
    backRoute: PropTypes.string,
};

export default BackButton;
