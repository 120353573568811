import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import MetricTabContext from './MetricTabContext';

const useStyles = makeStyles(() => ({
    tabBar: {
        display: 'flex',
        justifyContent: 'stretch',
        flexWrap: 'nowrap',
        listStyle: 'none',
        paddingBottom: '1.125rem',
        width: '100%',
    },
}));

const MetricTabBar = ({ currentTab, onChange, children }) => {
    const classes = useStyles();

    const onTabChange = (tabId) => {
        onChange(tabId);
    };

    return (
        <MetricTabContext.Provider value={{ currentTab, changeTab: onTabChange }}>
            <li className={classes.tabBar}>{children}</li>
        </MetricTabContext.Provider>
    );
};

MetricTabBar.propTypes = {
    currentTab: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};

export { MetricTabBar };
