/**
 *
 * OrganizationListTable
 *
 */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { brandsFormatter, dateFormatter, userCountFormatter } from 'Helpers/table-formatter';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { filterOrgs } from 'utils/filterOrgs';
import { compact } from 'lodash';
import { FlyoutAction } from 'shared/constants';
import Table from 'components/@shared/Table';
import { ShareCampaignDialogBox } from 'containers/OrganizationPage/OrgFlyouts/ShareCampaignFlyout/ShareCampaignDialogBox';
import { removeShareOrg as removeOrg, shareOrg } from 'containers/App/actions';
import useFeatureFlags from 'utils/featureFlags/useFeatureFlags';
import reducer, { updateFilteredOrganizations, updateOrganizationPageNumber } from '../../slice';
import { selectOrganizationFilter, selectOrgPageNumber } from '../../selectors';
import saga from '../../saga';
import { DialogAction, formatSubmitValues } from '../../utils';
import OrganizationListFilter from '../../OrganizationListFilter';

const campaignColumns = {
    name: {
        field: 'name',
        label: 'Organization',
        sortable: true,
        width: '208px',
    },
    brands: {
        field: 'brands',
        label: 'Brand',
        sortable: true,
        width: '432px',
        format: brandsFormatter,
    },
    type: {
        field: 'type',
        label: 'Type',
        sortable: true,
    },
    createdAt: {
        field: 'createdAt',
        label: 'Created on',
        sortable: true,
        format: dateFormatter,
    },
    userCount: {
        field: 'userCount',
        type: 'number',
        label: 'Active Users',
        sortable: true,
        format: userCountFormatter,
    },
    campaignCount: {
        field: 'campaignCount',
        type: 'number',
        label: 'Campaigns',
        sortable: true,
    },
};

function containIgnoreCase(object, fields, skw) {
    if (!skw) {
        return true;
    }
    if (fields) {
        // eslint-disable-next-line no-restricted-syntax
        for (const field of fields) {
            if (field === 'name') {
                const string = `${object[field]}`;
                if (string && string.toUpperCase().includes(skw.toUpperCase())) {
                    return true;
                }
            } else if (field === 'brands') {
                const brands = object[field];
                if (brands) {
                    for (let i = 0; i < brands.length; i++) {
                        if (brands[i] && brands[i].toUpperCase().includes(skw.toUpperCase())) {
                            return true;
                        }
                    }
                }
            }
        }
    }
    return false;
}

export function OrganizationListTable({
    organizations,
    brands,
    flyoutStatus,
    searchKeyword,
    searchIncludes,
    setFlyoutStatus,
    setSelectedItem,
    // Redux
    onUpdatePageNumber,
    pageNumber,
    onCampaignShareSubmit,
    removeShareOrg,
    organizationFilter,
    updateOrgFilter,
}) {
    useInjectReducer({ key: 'organizationPage', reducer });
    useInjectSaga({ key: 'organizationPage', saga });

    const featureFlags = useFeatureFlags();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogRemoveOpen, setDialogRemoveOpen] = useState(false);
    const [dialogBoxAction, setDialogBoxAction] = useState('');
    const [submitValues, setSubmitValues] = useState(null);

    useEffect(() => {
        onUpdatePageNumber(0);
    }, [searchIncludes, searchKeyword, onUpdatePageNumber]);

    const onCloseFlyoutModal = () => {
        setFlyoutStatus(FlyoutAction.NONE);
        setSubmitValues(null);
    };

    const onShareActionClick = (flyoutAction, organization) => {
        setSelectedItem(organization);
        setFlyoutStatus(flyoutAction);
    };

    const onOrgFilterChange = (filter) => {
        updateOrgFilter(filter);
        onUpdatePageNumber(0);
    };

    const tableActions = (organization) =>
        compact([
            {
                label: 'Share Campaign',
                onClick: () => {
                    onShareActionClick(FlyoutAction.SHARE, organization);
                    setSelectedItem(organization);
                },
            },
            {
                label: 'View Details',
            },
            featureFlags.showOrgManagement && {
                label: 'Edit Organization',
                onClick: () => {
                    setFlyoutStatus(FlyoutAction.EDIT_ORGANIZATION);
                    setSelectedItem(organization);
                },
            },
        ]);

    const onSubmitCampaignsProceed = () => {
        switch (dialogBoxAction) {
            case DialogAction.SHARE:
                onCampaignShareSubmit(submitValues);
                setDialogOpen(false);
                onCloseFlyoutModal();
                break;
            case DialogAction.UNSHARE:
                removeShareOrg(submitValues);
                setDialogRemoveOpen(false);
                setSubmitValues(null);
                break;
            default:
                break;
        }
    };

    const onSubmitCampaignsCancel = () => {
        setDialogOpen(false);
        setDialogRemoveOpen(false);
        setDialogBoxAction('');
        setSubmitValues(null);
    };

    const childTableActions = (organization) => {
        const removeShare = {
            label: 'Remove Share',
            onClick: (event, orgInfo) => {
                setDialogRemoveOpen(true);
                setDialogBoxAction(DialogAction.UNSHARE);
                const values = {
                    organizations: [organization],
                    brands: [{ name: orgInfo.brands[0], brandId: orgInfo.brandId }],
                };
                const removeOrgFormatted = formatSubmitValues(orgInfo.sharedFromOrg, values);
                setSubmitValues(removeOrgFormatted);
            },
        };

        return [removeShare];
    };

    const searchedAndFilteredOrgs = useCallback(
        (orgArray, orgsSearched, searchedKeyword) => {
            if (searchedKeyword) {
                onUpdatePageNumber(0);
                return orgsSearched;
            }
            return orgArray;
        },
        [onUpdatePageNumber],
    );

    const searchedOrgs = useMemo(() => {
        const filteredSearchFields = searchIncludes
            ?.filter((include) => include.checked)
            .map((include) => include.name);
        const organizationsToShow = organizations.filter((org) =>
            containIgnoreCase(org, filteredSearchFields, searchKeyword),
        );
        return organizationsToShow;
    }, [searchIncludes, organizations, searchKeyword]);

    const { filteredOrgs, ...filterStats } = useMemo(
        () => filterOrgs(searchedAndFilteredOrgs(organizations, searchedOrgs, searchKeyword), organizationFilter),
        [searchedAndFilteredOrgs, organizations, searchedOrgs, searchKeyword, organizationFilter],
    );

    let dialogBox = null;

    if (organizations) {
        switch (dialogBoxAction) {
            case DialogAction.SHARE:
                dialogBox = (
                    <ShareCampaignDialogBox
                        open={dialogOpen}
                        cancel={onSubmitCampaignsCancel}
                        submitValues={submitValues}
                        onSubmit={onSubmitCampaignsProceed}
                        dialogBoxAction={dialogBoxAction}
                    />
                );
                break;
            case DialogAction.UNSHARE:
                dialogBox = (
                    <ShareCampaignDialogBox
                        open={dialogRemoveOpen}
                        cancel={onSubmitCampaignsCancel}
                        submitValues={submitValues}
                        onSubmit={onSubmitCampaignsProceed}
                        dialogBoxAction={dialogBoxAction}
                    />
                );
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <div>{dialogBox}</div>
            <OrganizationListFilter
                organizations={organizations}
                brands={brands}
                filterStats={filterStats}
                onOrgFilterChange={onOrgFilterChange}
                currentOrgFilter={organizationFilter}
                open={flyoutStatus === FlyoutAction.ORG_FILTER}
                onFlyoutModalClose={onCloseFlyoutModal}
            />
            <Table
                data={filteredOrgs}
                columns={Object.values(campaignColumns)}
                actions={tableActions}
                childActions={childTableActions}
                pageNumber={pageNumber}
                onUpdatePageNumber={onUpdatePageNumber}
                // same org can share different brands so need to include brandId to make unique
                keyFn={(item) => (item?.brandId ? item.orgId + item.brandId : item.orgId)}
                sort={{ column: campaignColumns.createdAt, order: 'desc' }}
            />
        </div>
    );
}

OrganizationListTable.propTypes = {
    organizations: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            createdAt: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            userCount: PropTypes.number.isRequired,
            campaignCount: PropTypes.number.isRequired,
            brands: PropTypes.array.isRequired,
            orgId: PropTypes.string.isRequired,
        }),
    ).isRequired,
    brands: PropTypes.array,
    onUpdatePageNumber: PropTypes.func,
    pageNumber: PropTypes.number,
    removeShareOrg: PropTypes.func,
    onCampaignShareSubmit: PropTypes.func,
    organizationFilter: PropTypes.shape({
        keyword: PropTypes.string,
        includes: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                label: PropTypes.string,
            }),
        ),
    }),
    flyoutStatus: PropTypes.string.isRequired,
    setFlyoutStatus: PropTypes.func,
    setSelectedItem: PropTypes.func,
    updateOrgFilter: PropTypes.func.isRequired,
    searchKeyword: PropTypes.string,
    searchIncludes: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    organizationFilter: selectOrganizationFilter,
    pageNumber: selectOrgPageNumber,
});

export function mapDispatchToProps(dispatch) {
    return {
        onCampaignShareSubmit: (sharedData) => {
            dispatch(shareOrg(sharedData));
        },
        removeShareOrg: (organization) => {
            dispatch(removeOrg(organization));
        },
        onUpdatePageNumber: (pageNumber) => {
            dispatch(updateOrganizationPageNumber(pageNumber));
        },
        updateOrgFilter: (filter) => {
            dispatch(updateFilteredOrganizations(filter));
        },
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OrganizationListTable);
