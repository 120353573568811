import * as yup from 'yup';
import env from 'config/env';
import moment from 'moment';

const fieldKeySymbol = Symbol('fieldKey');
const fieldSchemaSymbol = Symbol('fieldSchema');

export function responseMapping(mapping, data) {
    const schema = parseSchema(mapping);

    if (env.BBY_ENV === 'test' || env.BBY_ENV === 'local' || process.env.NODE_ENV === 'test') {
        return schema.validateSync(data, { stripUnknown: true });
    }

    return schema.cast(data, { stripUnknown: true });
}

/**
 * Helper function to define a field with a different key in the response
 * @param {string} key key to pull data from in the response
 * @param {*} schema schema of the field
 * @returns {object} intermediate object used to create schema
 */
export function fieldFrom(key, schema) {
    return { [fieldKeySymbol]: key, [fieldSchemaSymbol]: schema };
}

export function mixed() {
    return yup.mixed().defined();
}

export function string() {
    return yup.string().defined();
}

export function number() {
    return yup.number().defined();
}

export function boolean() {
    return yup.boolean().defined();
}

export function date() {
    return yup
        .string()
        .test('is-iso-date-string', 'Must be a valid ISO date string', (value) =>
            moment(value, moment.ISO_8601, true).isValid(),
        );
}

export function array(value) {
    const valueSchema = parseSchema(value);
    return yup.array().of(valueSchema).defined();
}

export function object(objectMapping) {
    const fields = {};
    const renames = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(objectMapping)) {
        fields[key] = parseSchema(value);
        if (value?.[fieldKeySymbol]) {
            renames.push({ from: value[fieldKeySymbol], to: key });
        }
    }

    let schema = yup.object(fields);

    // eslint-disable-next-line no-restricted-syntax
    for (const { from, to } of renames) {
        schema = schema.from(from, to);
    }

    return schema.camelCase().defined();
}

function parseSchema(value) {
    if (value instanceof yup.Schema) {
        return value;
    }
    if (Array.isArray(value)) {
        return array(parseSchema(value[0]));
    }
    if (typeof value === 'object') {
        if (value[fieldKeySymbol]) {
            return parseSchema(value[fieldSchemaSymbol]);
        }

        return object(value);
    }

    throw new Error(`Unable to parse schema. Cannot read ${value}`);
}

/**
 * utcDateTransform - yup transform to read a date string or unix timestamp as UTC
 * @param {string} val
 * @param {string | number} originalVal
 * @returns string
 */
export function utcDateTransform(val, originalVal) {
    return moment.utc(originalVal).toISOString();
}
