import {
    CAMPAIGN_TYPE_DISPLAY,
    CAMPAIGN_TYPE_ONSITE_VIDEO,
    CAMPAIGN_TYPE_SPONSORED_RMP,
    CAMPAIGN_TYPE_PAID_SEARCH,
    CAMPAIGN_TYPE_SOCIAL,
    CAMPAIGN_TYPE_PROXIMITY,
    CAMPAIGN_TYPE_OFFSITE_DISPLAY,
    CAMPAIGN_TYPE_OFFSITE_VIDEO,
} from '../../Helpers/helpers';

export const campaignTypeOrder = [
    CAMPAIGN_TYPE_DISPLAY,
    CAMPAIGN_TYPE_ONSITE_VIDEO,
    CAMPAIGN_TYPE_SPONSORED_RMP,
    CAMPAIGN_TYPE_PAID_SEARCH,
    CAMPAIGN_TYPE_OFFSITE_DISPLAY,
    CAMPAIGN_TYPE_OFFSITE_VIDEO,
    CAMPAIGN_TYPE_SOCIAL,
    CAMPAIGN_TYPE_PROXIMITY,
];
