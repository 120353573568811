/**
 *
 * SummaryListFilter
 *
 */

import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, filter, map } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Divider, IconButton } from '@material-ui/core';
import classnames from 'classnames';
import TuneIcon from '@material-ui/icons/Tune';
import FlyoutModal, { FlyoutContent } from 'components/FlyoutModal';
import MultiSelect from 'components/FlyoutModal/MultiSelect';
import MultiCheckbox from 'components/FlyoutModal/MultiCheckbox';
import SummaryMultiRadio from 'components/FlyoutModal/SummaryMultiRadio';
import { comparator } from 'utils/sortComparator';
import useCampaigns from 'shared/hooks/useCampaigns';
import { RequestStatus } from 'shared/constants';
import { getSortedVehicles } from 'utils/filterUtils';
import { campaignStatusOptionsArray } from './constants';
import { activeFilterStyling } from '../../utils/filterUtils';

const useStyles = makeStyles((theme) => ({
    resetButton: {
        border: 'none',
        backgroundColor: theme.palette.standardWhite,
        color: '#0046BE',
        textTransform: 'capitalize',
        alignSelf: 'start',
        '&:hover': {
            background: 'none',
            color: theme.palette.darkBlue,
        },
        marginTop: '28px',
        marginLeft: '-8px',
    },
    submitButton: {
        height: 42,
        borderRadius: 4,
        padding: 0,
        marginBottom: '24px',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.humanBlue,
        border: 'none !important',
        color: theme.palette.standardWhite,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
    filterButton: {
        backgroundColor: 'white',
        border: `1px solid ${theme.palette.borderGray}`,
        borderRadius: '50%',
        height: '46px',
        width: '46px',
        marginLeft: '16px',
        '&:hover': {
            backgroundColor: '#F1F1F1',
        },
    },
    filterButtonActive: {
        '&.MuiIconButton-root': {
            backgroundColor: theme.palette.humanBlue,
            borderRadius: '50%',
            height: '46px',
            marginLeft: '16px',
            color: 'white',
        },
        '&:hover': {
            color: theme.palette.humanBlue,
            backgroundColor: '#F1F1F1',
            border: 'none',
        },
    },
}));

function createSelectionInfo(selectableOptions, selectedOptions, showCounts, stats = {}) {
    if (selectableOptions && selectedOptions) {
        const enrichedItems = selectableOptions.map((option) => {
            const count = stats[option.value] || 0;
            return {
                ...option,
                name: showCounts ? `${option.name} (${count})` : option.name,
                value: option.value,
            };
        });

        return map(enrichedItems, (item) => ({
            ...item,
            checked: selectedOptions.has(item.value),
            value: item.value,
        }));
    }
    return null;
}

const SummaryListFilter = ({
    currentSummaryFilter,
    dateFilter,
    filterMetadata,
    onSummaryFilterChange,
    campaignType,
    brandData,
}) => {
    const classes = useStyles();
    const [openFlyoutModal, setOpenFlyoutModal] = useState(false);
    const onOpenFlyoutModal = () => setOpenFlyoutModal(true);
    const onCloseFlyoutModal = () => {
        setTrackFilter(currentSummaryFilter);
        setOpenFlyoutModal(false);
    };

    const [trackFilter, setTrackFilter] = useState(currentSummaryFilter);

    useEffect(() => {
        setTrackFilter(currentSummaryFilter);
    }, [currentSummaryFilter]);

    let brandNames = [];
    if (brandData) {
        brandNames = trackFilter?.brands
            ?.map(({ value }) => {
                const brandInfo = brandData.find((brand) => brand.id === value);
                return brandInfo?.name || null;
            })
            .filter((name) => name !== null);
    }
    const countFilter = { ...trackFilter, brands: brandNames };

    if (campaignType) {
        countFilter.campaignTypes = [campaignType];
    }

    const { campaignTypeStats, categoryStats, statusStats, brandStats, loadStatus: countLoadStatus } = useCampaigns(
        countFilter,
        dateFilter,
    );

    const showCounts = countLoadStatus === RequestStatus.DONE;

    let brands = [];
    let categories = [];
    const campaignTypes = getSortedVehicles(filterMetadata).map((vehicle) => ({
        name: vehicle.getLabel(),
        value: vehicle.getCampaignType(),
    }));
    const campaignStatusOptions = campaignStatusOptionsArray;

    if (brandData) {
        brands = brandData.map((brand) => ({
            name: brand.name,
            value: brand.id,
        }));
    }

    if (filterMetadata?.category_groups) {
        categories = map(Object.keys(filterMetadata.category_groups), (category) => ({
            name: category,
            value: category,
        }));
    }

    const { selectedBrands, selectedCampaignTypes, selectedCategories, selectedCampaignStatuses } = useMemo(() => {
        const newBrandFilter = new Set(trackFilter?.brands?.map((b) => b.value));
        const newTypeFilter = new Set(trackFilter?.campaignTypes);
        const newCategoryFilter = new Set(trackFilter?.categories);
        const newStatusFilter = new Set(trackFilter?.status);

        return {
            selectedBrands: newBrandFilter,
            selectedCampaignTypes: newTypeFilter,
            selectedCategories: newCategoryFilter,
            selectedCampaignStatuses: newStatusFilter,
        };
    }, [trackFilter]);

    const onFilteredBrandsChange = (filterSelection) => {
        setTrackFilter({ ...trackFilter, brands: filterSelection });
    };

    const onFilteredTypesChange = (typeSelectionState) => {
        const newTypeFilter = typeSelectionState.filter((state) => state.checked).map((state) => state.value);
        setTrackFilter({ ...trackFilter, campaignTypes: newTypeFilter });
    };

    const onFilteredCategoryChange = (filterSelection) => {
        const newCategoryFilter = map(filterSelection, 'value');
        setTrackFilter({ ...trackFilter, categories: newCategoryFilter });
    };

    const onFilteredStatusChange = (statusSelectionState) => {
        const newStatusFilter = statusSelectionState.filter((state) => state.checked).map((state) => state.value);
        setTrackFilter({ ...trackFilter, status: newStatusFilter });
    };

    const filteredCategories = isEmpty(selectedCategories)
        ? []
        : filter(categories, ({ value }) => selectedCategories?.has(value)).map((c) => {
              const count = categoryStats[c.name] || 0;
              return {
                  ...c,
                  name: showCounts ? `${c.name} (${count})` : c.name,
              };
          });

    const filteredBrands = isEmpty(selectedBrands)
        ? []
        : filter(brands, ({ value }) => selectedBrands.has(value)).map((b) => {
              const count = brandStats[b.name];
              return { ...b, name: `${b.name} (${count || 0})` };
          });

    const campaignTypeSelections = createSelectionInfo(
        campaignTypes,
        selectedCampaignTypes,
        showCounts,
        campaignTypeStats,
    );
    const campaignStatusSelections = createSelectionInfo(
        campaignStatusOptions,
        selectedCampaignStatuses,
        showCounts,
        statusStats,
    );

    const onResetAllClick = () => {
        setTrackFilter({ brands: [], campaignTypes: [], categories: [], status: [] });
        onSummaryFilterChange({});
    };

    const onSubmitClick = () => {
        const result = trackFilter ? Object.values(trackFilter) : [];
        const isFilterSelected = result.some((filterOption) => filterOption && filterOption?.length > 0);
        if (isFilterSelected) {
            onSummaryFilterChange(trackFilter);
            onCloseFlyoutModal();
        } else {
            onSummaryFilterChange({});
            onCloseFlyoutModal();
        }
    };

    return (
        <div className={classes.tableTop}>
            <IconButton
                data-testid="summaryFilterFlyoutButton"
                onClick={onOpenFlyoutModal}
                className={activeFilterStyling(currentSummaryFilter, classes.filterButtonActive, classes.filterButton)}
            >
                <TuneIcon />
            </IconButton>
            <FlyoutModal
                title="Summary Filters"
                open={openFlyoutModal}
                onClose={onCloseFlyoutModal}
                bottom={
                    <>
                        <Button
                            data-testid="submit-user-form-button"
                            className={classnames(classes.submitButton)}
                            type="submit"
                            onClick={onSubmitClick}
                        >
                            Submit
                        </Button>
                        <Divider />
                        <Button className={classnames(classes.resetButton)} color="secondary" onClick={onResetAllClick}>
                            Reset All
                        </Button>
                    </>
                }
            >
                <FlyoutContent label="Brand">
                    <MultiSelect
                        id="brandFilterSelect"
                        placeholder="Search by brands..."
                        options={brands?.slice().sort((a, b) => comparator(a, b))}
                        selectedOptions={filteredBrands.sort((a, b) => comparator(a, b))}
                        onChange={onFilteredBrandsChange}
                    />
                </FlyoutContent>
                <FlyoutContent label="Campaign Type">
                    {campaignType ? (
                        <SummaryMultiRadio
                            optionsType="summaryCampaignType"
                            options={campaignTypeSelections}
                            isSorted={false}
                            campaignType={campaignType}
                        />
                    ) : (
                        <MultiCheckbox
                            optionsType="summaryCampaignType"
                            options={campaignTypeSelections}
                            onChange={onFilteredTypesChange}
                            isSorted={false}
                        />
                    )}
                </FlyoutContent>
                <FlyoutContent label="Category">
                    <MultiSelect
                        id="categoryFilterSelect"
                        placeholder="Search by categories..."
                        options={categories?.slice()?.sort((a, b) => comparator(a, b))}
                        selectedOptions={filteredCategories.sort((a, b) => comparator(a, b))}
                        onChange={onFilteredCategoryChange}
                    />
                </FlyoutContent>
                <FlyoutContent label="Status">
                    <MultiCheckbox
                        optionsType="summaryCampaignStatus"
                        options={campaignStatusSelections}
                        onChange={onFilteredStatusChange}
                        isSorted={false}
                    />
                </FlyoutContent>
            </FlyoutModal>
        </div>
    );
};

SummaryListFilter.propTypes = {
    currentSummaryFilter: PropTypes.object,
    campaignType: PropTypes.string,
    dateFilter: PropTypes.object,
    onSummaryFilterChange: PropTypes.func,
    filterMetadata: PropTypes.shape({
        category_groups: PropTypes.object,
        campaign_types: PropTypes.object,
    }),
    brandData: PropTypes.array,
};

SummaryListFilter.defaultProps = {
    onSummaryFilterChange: () => {},
};

export default SummaryListFilter;
