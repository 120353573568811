import Fuse from 'fuse.js';
import moment from 'moment';

/*
When searching campaigns, many campaigns are named almost identically, often time distinguished only by a year (e.g., FY24 vs FY25).
This can result in a typical relevance-based search placing campaigns the user does not want higher in results, purely based on
irrelevant details in the name. To deal with this issue, this search clumps similar campaigns together,
and then sorts campaigns that are equally close matches by start date.

In the search options, ignoreLocation and ignoreFieldNorm set to false remove factors that can cause similar matches to have
different scores.

In addition to disabling these options, the campaigns are grouped in the sort function by rounding each campaign's
relevance score down to the nearest 0.025 before sorting.

With these grouped scores, the campaigns are then sorted first by relevance, and then by state date.
*/

const SEARCH_OPTIONS = {
    shouldSort: false,
    threshold: 0.3,
    ignoreLocation: true,
    minMatchCharLength: 2,
    ignoreFieldNorm: true,
    includeScore: true,
    includeMatches: true,
    keys: ['campaign_id', 'salesforce_campaign_name', 'order_id', 'criteo_id', 'proximity_campaign_id', 'brand_name'],
};

function fuzzySearch({ data, term, fuse }) {
    const result = fuse.search(`${term}`);

    return term ? result : data;
}

const sortFn = (a, b) => {
    let scoreComparison = Math.floor(a.score * 40) - Math.floor(b.score * 40);
    if (scoreComparison === 0) {
        scoreComparison = moment(a.item.start_date).diff(moment(b.item.start_date)) * -1;
    }

    return scoreComparison;
};

/**
 *
 * @param {Object} param0 contains the dataset to search over
 *
 * @returns {function} function that performs the search
 *
 * A function to that takes a dataset and returns a function that searches over that dataset,
 * with customized fuzzy search options and sorting.
 *
 */
function createCampaignSearch(data) {
    const fuse = new Fuse(data, SEARCH_OPTIONS);

    return (term) => {
        if (term === '') {
            return [];
        }

        const results = fuzzySearch({ data, term, fuse });

        return results.sort(sortFn);
    };
}

export default createCampaignSearch;
